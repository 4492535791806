import React from 'react'
import '../../MainStyle.css';
import general from "../css/General.module.css";

const noticeDialog = ({ intentData, updateState }) => {

  return (
    <div className={[general.posFixed, general.respWidth, general.height100vh, general.viewCenter, general.bgLightBlack, intentData.noticeDialogShow ? general.zIndex_500 : general.zIndex_100].join(" ")} onClick={() => updateState(false)}>
      <div className={[general.noticeViewContents, general.viewCenter].join(" ")}>
        <div>
          <div className={[general.colorWhite, general.fontS22Px].join(" ")}>Notification</div>
        </div>

        <div className={[general.noticeTitle].join(" ")}>{intentData.noticeTitle}</div>
        <div className={[general.noticeNote, general.mgT20px].join(" ")}>{intentData.noticeNote}</div>

        <div
          className={[
            general.width90,
            general.viewCenter,
            general.colorWhite,
            general.fontS18Px,
            general.pd10px,
            general.mgT30px,
            general.borderRadius30px,
            general.bgCustomColor2
          ].join(" ")}
        // onClick={() => confirmWithdrawBalance()}
        >
          Confirm
        </div>
      </div>
    </div >
  );
};

export default noticeDialog;